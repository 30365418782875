import store from "@/state/store";

export default [
  {
    path: "/",
    name: "default",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/dashboards/default"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./views/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("./views/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    },
  },
  {
    path: "/404",
    name: "404",
    component: require("./views/utility/404").default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: "*",
    redirect: "404",
  },
  //BARCODE PAGE
  {
    path: "/barcode/index",
    name: "barcode_index",
    meta: { authRequired: true },
    component: () => import("./views/barcode/list"),
  },
  {
    path: "/barcode/bins",
    name: "barcode_bins",
    meta: { authRequired: true },
    component: () => import("./views/barcode/list"),
  },
  //PRODUCTS PAGE
  {
    path: "/products/index",
    name: "product",
    meta: { authRequired: true },
    component: () => import("./views/products/default"),
  },
  {
    path: "/identifier/index",
    name: "identifier_home",
    meta: { authRequired: true },
    component: () => import("./views/products/identifier"),
  },
  {
    path: "/identifier/create",
    name: "identifier_create",
    meta: { authRequired: true },
    component: () => import("./views/products/identifier_create"),
  },
  {
    path: "/identifier/createBulk",
    name: "identifier_create_bulk",
    meta: { authRequired: true },
    component: () => import("./views/products/identifier_create_bulk"),
  },
  {
    path: "/products/edit/:id",
    name: "product_edit",
    meta: { authRequired: true },
    component: () => import("./views/products/default"),
  },
  //ORDERS PAGE
  {
    path: "/orders/index",
    name: "order",
    meta: { authRequired: true },
    component: () => import("./views/orders/default"),
  },
  // SETTINGS PAGE
  {
    path: "/settings/general",
    name: "setting_general",
    meta: { authRequired: true },
    component: () => import("./views/settings/general"),
  },
  {
    path: "/settings/owners",
    name: "setting_owners",
    meta: { authRequired: true },
    component: () => import("./views/settings/general"),
  },
  {
    path: "/settings/users",
    name: "users",
    meta: { authRequired: true },
    component: () => import("./views/settings/general"),
  },
  {
    path: "/settings/groups",
    name: "groups",
    meta: { authRequired: true },
    component: () => import("./views/settings/general"),
  },
  {
    path: "/settings/applications",
    name: "applications",
    meta: { authRequired: true },
    component: () => import("./views/settings/general"),
  },
  {
    path: "/settings/store_categories",
    name: "store_categories",
    meta: { authRequired: true },
    component: () => import("./views/settings/general"),
  },
  {
    path: "/settings/social_medias",
    name: "social_medias",
    meta: { authRequired: true },
    component: () => import("./views/settings/general"),
  },
  {
    path: "/settings/item_specifics",
    name: "item_specifics",
    meta: { authRequired: true },
    component: () => import("./views/settings/general"),
  },
  {
    path: "/settings/shipping",
    name: "shipping",
    meta: { authRequired: true },
    component: () => import("./views/settings/general"),
  },
  {
    path: "/settings/daily_post",
    name: "daily_post",
    meta: { authRequired: true },
    component: () => import("./views/settings/general"),
  },
  {
    path: "/settings/presets",
    name: "presets",
    meta: { authRequired: true },
    component: () => import("./views/settings/general"),
  },
  {
    path: "/settings/scheduler",
    name: "scheduler",
    meta: { authRequired: true },
    component: () => import("./views/settings/general"),
  },
  {
    path: "/settings/flagged_keywords",
    name: "flagged",
    meta: { authRequired: true },
    component: () => import("./views/settings/general"),
  },
  {
    path: "/settings/product_tags",
    name: "product_tags",
    meta: { authRequired: true },
    component: () => import("./views/settings/general"),
  }, 
  {
    path: "/settings/ip-whitelist",
    name: "ip_whitelist",
    meta: { authRequired: true },
    component: () => import("./views/settings/general"),
  },
  {
    path: "/settings/modes",
    name: "mode",
    meta: { authRequired: true },
    component: () => import("./views/settings/general"),
  },
  {
    path: "/stats/sold_items",
    name: "stats_sold_items",
    meta: { authRequired: true },
    component: () => import("./views/stats/sold_items"),
  },
  {
    path: "/mail/:tab/:view?",
    name: "mail",
    meta: { authRequired: true },
    component: () => import("./views/messages/default"),
  },
];