var _self = {
    publicIp4: function () {
        return fetch('https://api4.ipify.org?format=json')
        .then(x => x.json())
        .then(({ ip }) => {
            return ip;
        })/* eslint-disable no-console */
        .catch(e=>console.log('IPV4 - ' + e.message))
    },
    publicIp6: function () {
        return fetch('https://api6.ipify.org?format=json')
        .then(x => x.json())
        .then(({ ip }) => {
            return ip;
        })/* eslint-disable no-console */
        .catch(e=>console.log('IPV6 - ' + e.message))
    },
    getUserIp: async function() {
        return Promise.all([
            _self.publicIp4(),
            //_self.publicIp6()
        ]);
    }
};

module.exports = _self;
