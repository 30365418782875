import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
// import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
// import * as VueGoogleMaps from 'vue2-google-maps'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import vco from "v-click-outside"
import router from './router/index'
import Scrollspy from 'vue2-scrollspy';
import VueSweetalert2 from 'vue-sweetalert2';

import Notifications from 'vue-notification'

import "../src/design/app.scss";

import store from '@/state/store'

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'

import 'element-ui/lib/theme-chalk/index.css';

import App from './App.vue'

import i18n from './i18n'

import tinymce from 'vue-tinymce-editor'

import Toasted from 'vue-toasted';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import VeeValidate from 'vee-validate';
import ScrollDiv from 'vue-scroll-div';

//iframe resizer
/*import iframeResize from 'iframe-resizer/js/iframeResizer';

Vue.directive('resize', {
  bind: function(el, { value = {} }) {
    el.addEventListener('load', () => iframeResize(value, '#myIframe'))
  },
  unbind: function (el) {
    el.iFrameResizer.removeListeners();
  }
})*/

Vue.use(ElementUI, { locale });
Vue.use(Toasted)
Vue.component('tinymce', tinymce)
Vue.use(VueRouter)
Vue.use(vco)
Vue.use(ScrollDiv)
Vue.use(Scrollspy);
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)
Vue.config.productionTip = false
Vue.use(Toast);

Vue.use(Notifications)

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueMask)
// Vue.use(require('vue-chartist'))
Vue.use(VueSweetalert2);
Vue.use(Loading);

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields'
}) 

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
//     libraries: 'places',
//   },
//   installComponents: true
// })
// Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
