import { userService } from '../../helpers/authservice/user-api-user.service';
// import router from '../../router/index'
let user = localStorage.getItem('user')
            ? JSON.parse(localStorage.getItem('user'))
            : false;
export const state = user
    ? { status: { loggeduser: true }, user }
    : { status: {}, user: null };

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    apiLogin({ dispatch, commit }, { email, password }) {
        commit('loginRequest', { email });
        return userService.login(email, password)
            .then(user => {
                let redirectUrl = '/'
                if (!user.groups.includes('admin')) {
                    if (user.groups.includes('identifier')) {
                        redirectUrl = '/identifier/index?tab=new'
                    } else if (user.groups.includes('lister')) {
                        redirectUrl = '/products/index'
                    } else if (user.groups.includes('shipper')) {
                        redirectUrl = '/orders/index'
                    }
                }
                commit('loginSuccess', user);
                location.href = redirectUrl;
            }).catch(error => {
                error = (error.message.includes('440')) ? 'Unauthorized user access' : error.message;
                commit('loginFailure', error);
                dispatch('notification/error', error, { root: true });
            });
    },
    // Logout the user
    apiLogout({ commit }) {
        userService.logout();
        commit('logout');
    },
    // register the user
    /*registeruser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    dispatch('notification/success', 'Registration successful', { root: true });
                    router.push('/login');
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    }*/
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    }
};

