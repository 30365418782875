import { authHeader } from './auth-header';
// import axios from "axios";
import { PostApiAction } from '../apiActions';

export const userService = {
    login,
    logout,
    register,
    getAll,
};

function login(email, password) {
    return PostApiAction("admin/auth/login", {
        email,
        password,
      }).then((res) => {
        if (res.status == 'success') {
            //store user detail
            localStorage.setItem('user', JSON.stringify(res.data));
            //store user token separately
            localStorage.setItem('token', res.data.token);
            return res.data;           
        } else {
            throw new Error(res.message)
        }
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
