import axios from "axios";
import { getUserIp } from "../lib/main";
import Vue from "vue";
const statusCode = [401, 404, 409];

//For a post request to api without authorization header
/* eslint-disable no-console */
function PostApiAction(url, obj, customHeader = {}) {
  return new Promise((resolve, reject) => {
    getUserIp().then(ips => {
    //attach both v4&v6 ips
    let ipHeaders = {};
    if (ips[0]) {//ipv4
      ipHeaders['x-ipaddr-v4'] = ips[0];
    }
    if (ips[1]) {//ipv6
      ipHeaders['x-ipaddr-v6'] = ips[1];
    }
    let headers = Object.assign(ipHeaders, customHeader);
    axios
      .post(process.env.VUE_APP_BACKEND_APP_URL + url, obj, { headers })
      .then((res) => {
        let data = res.data;
        resolve(data);
      })
      .catch((error) => {
        let data = error.response.data;
        if (statusCode.includes(data.status)) {
          Vue.toasted.error(data.message, { position: 'top-center' });
        }
        reject(error);
      });
    });
  });
}

//For a get request to api without authorization header
function GetApiAction(url,params={}) {
  return new Promise((resolve, reject) => {
    getUserIp().then (ips => {
      //attach both v4&v6 ips
      if (typeof params.headers === 'undefined') {
        params.headers = {};
      }
      if (ips[0]) {//ipv4
        params.headers['x-ipaddr-v4'] = ips[0];
      }
      if (ips[1]) {//ipv6
        params.headers['x-ipaddr-v6'] = ips[1];
      }
      axios
        .get(process.env.VUE_APP_BACKEND_APP_URL + url,params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          let data = error.response.data;
          if (statusCode.includes(data.status)) {
            Vue.toasted.error(data.message, { position: 'top-center' });
          }
          reject(error);
        });
    });
  });
}

//For a get request to api with authorization header
function GetApiActionWithAuthorizationOnly(url,params={}) {
  let user = JSON.parse(localStorage.getItem("user")) || {token: ''},
    header = { headers: { Authorization: `Bearer ${user.token}` },params };
  return new Promise((resolve, reject) => {
    axios
    .get(process.env.VUE_APP_BACKEND_APP_URL + url, header)
    .then((res) => {
      resolve(res.data);
    })
    .catch((error) => {
      let data = error.response.data;
      console.log(process.env.VUE_APP_BACKEND_APP_URL + url + " : " + data.status)
      if (statusCode.includes(data.status)) {
        Vue.toasted.error(data.message, { position: 'top-center' });
      }
      //Session Expires Check
      else if (data.autoLogout) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.location.href = '/login'
      }
      reject(data);
    });
  });
}

//For a get request to api with authorization header
function GetApiActionWithAuthorization(url,params={}) {
  let user = JSON.parse(localStorage.getItem("user")) || {token: ''},
    header = { headers: { Authorization: `Bearer ${user.token}` },params };
  return new Promise((resolve, reject) => {
    /*getUserIp().then (ips => {
      //attach both v4&v6 ips
      if (ips[0]) {//ipv4
        header.headers['x-ipaddr-v4'] = ips[0];
      }
      if (ips[1]) {//ipv6
        header.headers['x-ipaddr-v6'] = ips[1];
      }*/
      let hostname = params.hostname ? params.hostname : process.env.VUE_APP_BACKEND_APP_URL
      delete header.hostname
      axios
      .get(hostname + url, header)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        let data = error.response.data;
        console.log(process.env.VUE_APP_BACKEND_APP_URL + url + " : " + data.status)
        if (statusCode.includes(data.status)) {
          Vue.toasted.error(data.message, { position: 'top-center' });
        }
        //Session Expires Check
        else if (data.autoLogout) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          window.location.href = '/login'
        }
        reject(data);
      });
    });
  //});
}

//For a post request to api with authorization
function PostApiWithAuthorizationAction(url, obj, customHeader = {}) {
  let user = JSON.parse(localStorage.getItem("user")),
  header = {
    headers: Object.assign(customHeader, { Authorization: `Bearer ${user.token}` })
  };
  return new Promise((resolve, reject) => {
    /*getUserIp().then (ips => {
      //attach both v4&v6 ips
      if (ips[0]) {
        header.headers['x-ipaddr-v4'] = ips[0];
      }
      if (ips[1]) {
        header.headers['x-ipaddr-v6'] = ips[1];
      }*/
      axios
        .post(process.env.VUE_APP_BACKEND_APP_URL + url, obj, header)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          let data = error.response.data;
          if (statusCode.includes(data.status)) {
            Vue.toasted.error(data.message, { position: 'top-center' });
          }
          //Session Expires Check
          else if (data.status === 440) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            Vue.toasted.error(data.message, { position: 'top-center' });
            window.location.href = '/login'
          }
          reject(data);
        });
    });
  //});
}

//For a post request to api with authorization
function PostApiWithAuthorizationActionOnly(url, obj, customHeader = {}) {
  let user = JSON.parse(localStorage.getItem("user")),
  header = {
    headers: Object.assign(customHeader, { Authorization: `Bearer ${user.token}` })
  };
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_BACKEND_APP_URL + url, obj, header)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        let data = error.response.data;
        if (statusCode.includes(data.status)) {
          Vue.toasted.error(data.message, { position: 'top-center' });
        }
        //Session Expires Check
        else if (data.status === 440) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          Vue.toasted.error(data.message, { position: 'top-center' });
          window.location.href = '/login'
        }
        reject(data);
      });
  });
}

export {
  GetApiAction,
  PostApiAction,
  PostApiWithAuthorizationAction,
  GetApiActionWithAuthorization,
  GetApiActionWithAuthorizationOnly,
  PostApiWithAuthorizationActionOnly
};
